




































































































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { UserApi } from "@/networks/UserApi";
import { EventApi } from "@/networks/EventApi";
import { CallApi } from "@/networks/CallApi";
import { ContractApi } from "@/networks/ContractApi";
import moment from "moment-jalaali";
import Event from "@/views/components/pages/File/FileEvent.vue";
import Contract from "@/views/components/pages/File/FileContract.vue";
import Phone from "@/views/components/pages/File/FilePhone.vue";
import Transaction from "@/views/components/pages/File/FileTransactions.vue";
import { AxiosResponse } from "axios";
@Component({
  components: {
    Event,
    Contract,
    Phone,
    Transaction
  }
})
export default class File_Profile extends Vue {
  user: any = {};
  years = [1401, 1400, 1399, 1398, 1397, 1396, 1395, 1394, 1393, 1392];
  year = 1401;
  file = null;

  items: any = [
    {
      id: 1,
      mounth: "فروردین",
      items: []
    },
    {
      id: 2,
      mounth: "اردیبهشت",
      items: []
    },
    {
      id: 3,
      mounth: "خرداد",
      items: []
    },
    {
      id: 4,
      mounth: "تیر",
      items: []
    },
    {
      id: 5,
      mounth: "مرداد",
      items: []
    },
    {
      id: 6,
      mounth: "شهریور",
      items: []
    },
    {
      id: 7,
      mounth: "مهر",
      items: []
    },
    {
      id: 8,
      mounth: "آبان",
      items: []
    },
    {
      id: 9,
      mounth: "آذر",
      items: []
    },
    {
      id: 10,
      mounth: "دی",
      items: []
    },
    {
      id: 11,
      mounth: "بهمن",
      items: []
    },
    {
      id: 12,
      mounth: "اسفند",
      items: []
    }
  ];
  async mounted() {
    if (this.$route.query.userId) {
      let id: any = this.$route.query.userId;
      const { data } = await new UserApi().getUser(id);
      this.user = data.data;
    } else {
      this.user = JSON.parse(localStorage.getItem("user") as string);
    }
    this.events();
  }
  @Watch("year")
  getAgain() {
    this.items = [
      {
        id: 1,
        mounth: "فروردین",
        items: []
      },
      {
        id: 2,
        mounth: "اردیبهشت",
        items: []
      },
      {
        id: 3,
        mounth: "خرداد",
        items: []
      },
      {
        id: 4,
        mounth: "تیر",
        items: []
      },
      {
        id: 5,
        mounth: "مرداد",
        items: []
      },
      {
        id: 6,
        mounth: "شهریور",
        items: []
      },
      {
        id: 7,
        mounth: "مهر",
        items: []
      },
      {
        id: 8,
        mounth: "آبان",
        items: []
      },
      {
        id: 9,
        mounth: "آذر",
        items: []
      },
      {
        id: 10,
        mounth: "دی",
        items: []
      },
      {
        id: 11,
        mounth: "بهمن",
        items: []
      },
      {
        id: 12,
        mounth: "اسفند",
        items: []
      }
    ];
    this.events();
  }
  async events() {
    let form = {
      date_from: moment(this.year, "jYYYY")
        .startOf("jYear")
        .format("YYYY-MM-DD"),
      date_to: moment(this.year, "jYYYY").endOf("jYear").format("YYYY-MM-DD"),
      user_id: this.$route.query.userId
        ? this.$route.query.userId
        : this.user.id
    };
    let { data }: AxiosResponse = await new EventApi().list({
      ...form,
      page: -1
    });
    for (let item of data.data) {
      let mounth = moment(item.created_at).format("jM");
      item.component = "Event";
      this.items[mounth - 1].items.push(item);
    }
    data = await new CallApi().list({
      ...form,
      page: -1
    });
    for (let item of data.data.data) {
      let mounth = moment(item.created_at).format("jM");
      item.type_original = "phone";
      item.component = "Phone";
      this.items[mounth - 1].items.push(item);
    }
    data = await new ContractApi().list({
      ...form,
      page: -1
    });
    for (let item of data.data.data) {
      let mounth = moment(item.created_at).format("jM");
      item.type_original = "contract";
      item.component = "Contract";
      this.items[mounth - 1].items.push(item);
    }
    data = await new UserApi().getUserTransactionsList({
      ...form,
      page: -1
    });
    for (let item of data.data.data) {
      let mounth = moment(item.created_at).format("jM");
      item.type_original = "transaction";
      item.component = "Transaction";
      this.items[mounth - 1].items.push(item);
    }
    // api for transactions component : Transaction
  }
}
