













import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
@Component
export default class component_name extends Vue {
  @Prop()
  event!: any;
  JalaaliDate(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
  migration(number: number): string {
    switch (number) {
      case 1:
        return "تحصیلی";
      case 2:
        return "کاری";
      case 3:
        return "سرمایه گذاری";
      case 4:
        return "سرچ";
      default:
        return "دیگر";
    }
  }
}
