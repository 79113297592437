











import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";

@Component
export default class component_name extends Vue {
  @Prop()
  event!: any;
}
