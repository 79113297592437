
















import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
@Component
export default class component_name extends Vue {
  @Prop()
  event!: any;
  ShowTime(date: any) {
    return moment(date).format("HH:mm  |  jDD-jMM-jYYYY");
  }
}
